import { Component, OnInit } from '@angular/core'
import { ConfigService } from '@shared/services/config.service'
import { ApiService } from '@shared/services/api.service'
import { ServiceNotification } from '@shared/interfaces'
import { RouterOutlet } from '@angular/router'
import { TranslatePipe } from '@ngx-translate/core'

@Component({
  selector: 'app-flow-layout',
  templateUrl: './flow-layout.component.html',
  styleUrls: ['./flow-layout.component.scss'],
  standalone: true,
  imports: [RouterOutlet, TranslatePipe],
})
export class FlowLayoutComponent implements OnInit {
  serviceNotification: ServiceNotification

  constructor(
    public config: ConfigService,
    public api: ApiService,
  ) {}

  ngOnInit() {
    this.api.getServiceNotification().subscribe(response => {
      this.serviceNotification = response
    })
  }
}
